<template>
  <div id="modalApproveManager" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Aprovar Formulário</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="approve">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12">
                <p>Confirma a aprovação do formulário?</p>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link text-success"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-success"
              title="Confirmar"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>Aprovar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'ModalApproveManager',
  props: {
    companyCode: {
      type: String,
      required: true,
    },
    stepForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalSize: 'modal-sm',
      disableButton: false,
    }
  },
  emits: ['closeModal'],
  methods: {
    async approve() {
      try {
        this.disableButton = true
        const result = await this.$store.dispatch('approveManagerEvaluation', {
          companyCode: this.companyCode,
          data: this.stepForm,
        })
        hideModal('modalApproveManager')
        this.$emit('closeModal', true)
        this.$root.$refs.notification.success(result.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    showModal('modalApproveManager')
  },
}
</script>
