<template>
  <div id="modalRepproveManager" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Aprovar Formulário</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="repprove">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12">
                <p>Confirma a reprovação do formulário?</p>
                <p>Selecione os pontos de atenção:</p>
                <div class="table-responsive">
                  <table
                    class="table table-sm fs-sm table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Natureza</th>
                        <th>Mes</th>
                        <th>Valor</th>
                        <th v-if="showTotalColumn">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in stepForm.registers"
                        :key="index"
                      >
                        <td>
                          <input
                            type="checkbox"
                            :value="item"
                            v-model="listReprove"
                          />
                        </td>
                        <td>{{ item.CODTBORCAMENTO }} - {{ item.NATUREZA }}</td>
                        <td>{{ item.MES }}</td>
                        <td>{{ formatToCurrencyBr(item.VALOR) }}</td>
                        <td v-if="showTotalColumn">
                          {{ formatToCurrencyBr(item.TOTAL) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="form-group">
                  <label for="justify">Justificativa</label>
                  <textarea
                    id="justify"
                    class="form-control"
                    rows="3"
                    v-model="justify"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link text-danger"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              title="Confirmar"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>Reprovar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { showModal, hideModal } from '@/utils/modal'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'ModalRepproveManager',
  props: {
    companyCode: {
      type: String,
      required: true,
    },
    stepForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatToCurrencyBr,
      modalSize: 'modal-lg',
      disableButton: false,
      listReprove: [],
      justify: '',
    }
  },
  emits: ['closeModal'],
  computed: {
    showTotalColumn() {
      return this.stepForm.registers.some((item) => item.TOTAL !== undefined)
    },
  },
  methods: {
    async repprove() {
      try {
        this.disableButton = true
        const result = await this.$store.dispatch('repproveManagerEvaluation', {
          companyCode: this.companyCode,
          data: this.stepForm,
          listReprove: this.listReprove,
          justify: this.justify,
        })
        hideModal('modalRepproveManager')
        this.$emit('closeModal', true)
        this.$root.$refs.notification.success(result.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    showModal('modalRepproveManager')
  },
}
</script>
