<template>
  <div class="row">
    <div class="card-body p-1 col-12">
      <h6 class="mb-1 text-center text-indigo">Contratações</h6>
    </div>
    <div class="card-body p-1" v-if="report.newColaborators.length === 0">
      <p class="text-center">Nenhuma contratação orçada</p>
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Função</th>
            <th>Centro de Custo</th>
            <th>Seção</th>
            <th>Mês</th>
            <th>Salário</th>
            <th>Insalub.</th>
            <th>Pericul.</th>
            <th>Adic. Noturno</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="colaborador in report.newColaborators"
            :key="colaborador.IDGERCPC"
          >
            <td>{{ colaborador.FUNCAO }}</td>
            <td>
              {{ colaborador.CODCCUSTO }} - {{ colaborador.CENTRODECUSTO }}
            </td>
            <td>{{ colaborador.CODSECAO }} - {{ colaborador.SECAO }}</td>
            <td>{{ colaborador.MES }}</td>
            <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
            <td>{{ colaborador.INSALUBRIDADE == 1 ? 'Sim' : 'Não' }}</td>
            <td>{{ colaborador.PERICULOSIDADE == 1 ? 'Sim' : 'Não' }}</td>
            <td>{{ colaborador.ADICIONALNOTURNO == 1 ? 'Sim' : 'Não' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-body p-1 col-12">
      <h6 class="mb-1 mt-2 text-center text-indigo">Demissões</h6>
    </div>
    <div class="card-body p-1" v-if="report.demissions.length === 0">
      <p class="text-center">Nenhuma demissão orçada</p>
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Função</th>
            <th>Centro de Custo</th>

            <th>Mês</th>
            <th>Salário</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="colaborador in report.demissions"
            :key="colaborador.IDGERCPC"
          >
            <td>{{ colaborador.CARGO }}</td>
            <td>
              {{ colaborador.NOME }}
            </td>
            <td>{{ colaborador.MES }}</td>
            <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-body p-1 col-12">
      <h6 class="mb-1 text-center text-indigo">Promoções</h6>
    </div>
    <div class="card-body p-1" v-if="report.promotions.length === 0">
      <p class="text-center">Nenhuma promoção orçada</p>
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Função</th>
            <th>Colaborador</th>
            <th>Mês</th>
            <th>Salário</th>
            <th>Novo Salário</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="colaborador in report.promotions"
            :key="colaborador.IDGERCPM"
          >
            <td>{{ colaborador.CARGO }}</td>
            <td>
              {{ colaborador.NOME }}
            </td>
            <td>{{ colaborador.MES }}</td>
            <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
            <td>{{ formatToCurrencyBr(colaborador.NOVOSALARIO) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-body p-1 col-12">
      <h6 class="mb-1 text-center text-indigo">Manter</h6>
    </div>
    <div class="card-body p-1" v-if="report.keepColaborators.length === 0">
      <p class="text-center">
        Nenhum colaborador foi mantido na mesma condição.
      </p>
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Função</th>
            <th>Centro de Custo</th>

            <th>Salário</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="colaborador in report.keepColaborators"
            :key="colaborador.IDGERCPM"
          >
            <td>{{ colaborador.CARGO }}</td>
            <td>
              {{ colaborador.NOME }}
            </td>
            <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'ReportCollaborador',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      formatToCurrencyBr,
      selectedCollaborator: null,
      selectedItem: null,
      showModalCreateOrEditCollaborator: false,
      showModalDeleteNewCollaboratorPersonalCost: false,
      isLoadingData: false,
      months: [
        { value: '01', name: 'Janeiro' },
        { value: '02', name: 'Fevereiro' },
        { value: '03', name: 'Março' },
        { value: '04', name: 'Abril' },
        { value: '05', name: 'Maio' },
        { value: '06', name: 'Junho' },
        { value: '07', name: 'Julho' },
        { value: '08', name: 'Agosto' },
        { value: '09', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' },
      ],
    }
  },
  emits: ['reloadData'],

  methods: {
    openModalNewCollaborator() {
      this.selectedCollaborator = null
      this.showModalCreateOrEditCollaborator = true
    },
    deleteNewCollaborator(item) {
      this.selectedItem = item
      this.showModalDeleteNewCollaboratorPersonalCost = true
    },
    editNewCollaborator(id) {
      this.selectedCollaborator = id
      this.showModalCreateOrEditCollaborator = true
    },
    closeModal(needReload) {
      this.showModalCreateOrEditCollaborator = false
      if (needReload) {
        this.$emit('reloadData')
      }
    },
  },
}
</script>
