import commons from './modules/commons/commons'
import auth from './modules/auth/auth'
import companies from './modules/params/companies'
import flow from './modules/flow/flow'
import steps from './modules/flow/steps'
import forms from './modules/flow/forms'
import filler from './modules/flow/filler'
import draft from './modules/forms/draft'
import commercial from './modules/forms/commercial'
import purchasing from './modules/forms/purchasing'
import laborCost from './modules/forms/laborCost'
import controllershipEvaluation from './modules/forms/controllershipEvaluation'
import manager from './modules/forms/manager'
import attachment from './modules/forms/attachment'
import dre from './modules/forms/dre'

export default {
  commons,
  auth,
  companies,
  flow,
  steps,
  filler,
  forms,
  draft,
  commercial,
  purchasing,
  laborCost,
  controllershipEvaluation,
  manager,
  attachment,
  dre,
}
