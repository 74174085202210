<template>
  <div id="modalDetailsDre" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ selecedDetails[0] }}:</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>

        <div class="modal-body">
          <LoadingData v-if="isLoadingData" />
          <div class="table-responsible" v-else>
            <table class="table">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Data</th>
                  <th>Responsável</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="attachment in attachments" :key="attachment.id">
                  <td>{{ attachment.ARQUIVO }}</td>
                  <td>{{ attachment.CRIADOEM }}</td>
                  <td>{{ attachment.CRIADOPOR }}</td>
                  <td>
                    <a
                      :href="`${apiBaseUrl}/uploads/attachments/${companyCode}/${attachment.IDFORMULARIOETAPA}/${attachment.ARQUIVO}`"
                      download
                      target="_blank"
                      class="btn btn-link"
                      title="Download"
                    >
                      <i class="ph-download"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer border-top-0 pt-0">
          <button
            type="button"
            class="btn btn-link"
            data-bs-dismiss="modal"
            title="Cancelar"
            @click="$emit('closeModal', false)"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showModal, hideModal } from '@/utils/modal'
import LoadingData from '@/components/commons/globals/LoadingData.vue'

export default {
  name: 'ModalAttachment',
  props: {
    item: {
      type: Object,
      required: true,
    },
    details: {
      type: Array,
      required: true,
    },
  },
  components: {
    LoadingData,
  },
  data() {
    return {
      modalSize: 'modal-lg',
    }
  },

  methods: {},

  mounted() {
    showModal('modalDetailsDre')
  },
}
</script>
