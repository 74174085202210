<template>
  <div>
    <ModalDeletePersonalCostManager
      v-if="showDeleteModal"
      :selectedItem="selectedItem"
      @closeModal="closeModal"
    />
    <ModalEditPersonalCostManager
      v-if="showEditModal"
      :selectedItem="selectedItem"
      @closeModal="closeModal"
    />
    <div class="card-body col-12">
      <h6 class="mb-1 mt-2 text-center text-primary">Custos</h6>
    </div>
    <div class="table-responsive table-scrollable">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th class="text-center">Versão</th>
            <th>Centro de Custo</th>
            <th>Natureza</th>
            <th class="text-center">Competência</th>
            <th class="text-center">Custo Unitário</th>
            <th class="text-center" v-if="!disableEdit">Excluir</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="registers.length === 0">
            <td colspan="6" class="text-center">
              Nenhum custo foi adicionado.
            </td>
          </tr>
          <tr v-for="(item, key) in registers" :key="key">
            <td class="text-center">
              <span
                v-if="item.REVISAR"
                class="bg-yellow bg-opacity-10 text-warning lh-1 rounded-pill p-2"
              >
                <i class="ph-warning"></i>
              </span>
              <span
                v-else
                class="bg-primary bg-opacity-10 text-primary lh-1 rounded-pill p-2"
              >
                <span class="letter-icon"> {{ item.VERSAO }}</span>
              </span>
            </td>
            <td>{{ item.CODCCUSTO }} - {{ item.CENTRODECUSTO }}</td>
            <td>{{ item.CODTBORCAMENTO }} - {{ item.NATUREZA }}</td>
            <td class="text-center">{{ item.MES }}</td>
            <td class="text-center">
              {{ formatToCurrencyBr(item.VALOR) }}
            </td>
            <td class="text-center p-0" v-if="!disableEdit">
              <button
                title="Editar"
                class="btn btn-warning btn-sm me-2"
                @click="editPersonalCostRegister(item)"
              >
                <i class="ph ph-pencil"></i>
              </button>
              <button
                class="btn btn-danger btn-sm"
                @click="deletePersonalCostRegister(item)"
              >
                <i class="ph ph-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <div></div>
        <div v-if="!onlyView">
          <button class="btn btn-success" @click="finish">
            <i class="ph-check me-2"></i> Finalizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalDeletePersonalCostManager from '@/components/forms/personalCostManager/ModalDeletePersonalCost.vue'
import ModalEditPersonalCostManager from '@/components/forms/personalCostManager/ModalEditPersonalCostManager.vue'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'RegisterPersonalCostManager',

  props: {
    form: {
      type: Object,
      required: true,
    },
    stepId: {
      type: Number,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    registers: {
      type: Array,
      required: true,
    },
    onlyView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ModalDeletePersonalCostManager,
    ModalEditPersonalCostManager,
  },
  data() {
    return {
      selectedItem: null,
      dataByMonths: [],
      formatToCurrencyBr,
      showDeleteModal: false,
      showEditModal: false,
    }
  },
  emits: ['updateForms'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },
  methods: {
    async finish() {
      try {
        this.disableButton = true

        const response = await this.$store.dispatch(
          'finishManagerRegistersForm',
          {
            form: {
              companyCode: this.companyCode,
              stepId: this.stepId,
              formId: this.form.IDFORMULARIO,
              formStepId: this.form.IDFORMETAPA,
            },
            companyCode: this.companyCode,
          },
        )
        this.$root.$refs.notification.success(response.message)
        this.$emit('updateForms')
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    deletePersonalCostRegister(item) {
      this.selectedItem = item
      this.showDeleteModal = true
    },
    editPersonalCostRegister(item) {
      this.selectedItem = item
      this.showEditModal = true
    },
    closeModal(needReload) {
      this.showDeleteModal = false
      this.showEditModal = false
      if (needReload) {
        this.$emit('updateForms')
      }
    },
  },
}
</script>
