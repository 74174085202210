<template>
  <div class="card">
    <ModalDetails
      v-if="showModalDetails"
      :item="selectedItem"
      :details="selectedDetails"
      @closeModal="showModalDetails = false"
    />
    <div class="card-header p-1">
      <h4 class="mb-0 ms-2 text-primary text-center">DRE</h4>
      <p class="opacity-50 text-center">
        {{ disableEdit ? 'Avaliado' : 'Aguardando Avaliação' }}
      </p>
    </div>
    <div class="card-body" v-if="isLoadingDre"><LoadingData /></div>
    <div v-else>
      <div class="table-responsive table-bordered table-striped">
        <table class="table table-sm fs-sm">
          <thead>
            <tr>
              <th>Item</th>
              <th>Janeiro</th>
              <th>Fevereiro</th>
              <th>Março</th>
              <th>Abril</th>
              <th>Maio</th>
              <th>Junho</th>
              <th>Julho</th>
              <th>Agosto</th>
              <th>Setembro</th>
              <th>Outubro</th>
              <th>Novembro</th>
              <th>Dezembro</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dre" :key="index" :class="item.class">
              <td class="cursor-pointer">
                {{ item.name }}
              </td>
              <td
                v-for="(mes, index2) in item.meses"
                :key="index2"
                :class="mes.details.length > 0 ? 'cursor-pointer' : ''"
                @click="selectItem(item, mes)"
              >
                {{ formatToCurrencyBr(mes.value) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import { formatToCurrencyBr } from '@/utils/currency'
import ModalDetails from '@/components/forms/dre/ModalDetails.vue'

export default {
  name: 'FormDre',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  components: {
    LoadingData,
    ModalDetails,
  },
  data() {
    return {
      pageTitle: 'DRE',
      formatToCurrencyBr,
      isLoadingDre: false,
      showAvaliationModal: false,
      selectedItem: {},
      selectedDetails: [],
      showModalDetails: false,
      disableEdit: false,
      dre: [],
    }
  },
  emits: ['updateForms'],

  methods: {
    closeModal() {
      this.showAvaliationModal = false
    },
    selectItem(item, mes) {
      this.selectedItem = item
      this.selectedDetails = mes.details
      this.showModalDetails = true
      console.log(item, mes)
    },
    async getDre() {
      try {
        this.isLoadingDre = true
        this.dre = await this.$store.dispatch('getDre', this.companyCode)
        this.isLoadingDre = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getDre()
  },
}
</script>
